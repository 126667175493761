import React, { useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Link, graphql, useStaticQuery } from "gatsby";

import { MenuContext } from '../context/menu-context.js';
import * as Gs from '../globals/styledComponents';

import Menu from '../components/utils/Menu';
import Layout from "../components/layout";
import Header from '../components/header';
import SEO from "../components/seo";
import Row from "../components/Row";
import Footer from '../components/footer.js';

const SectionOne = styled.section`
    ${tw`relative items-center flex flex-wrap w-screen h-auto bg-black pb-32 z-0`};
    height: 61.8vh;

`
const SectionTwo = styled.section`
    ${tw`relative w-screen min-h-screen bg-white z-10 py-16 `};
    border-radius: 3rem;
    transform: translateY(-4rem);
    // box-shadow: 0 -0.5rem 1rem rgba(10, 10, 20, 0.1);
`

const SectionTitle = styled.h3`
    ${tw`text-150gr my-8 font-semibold font-primary text-grey-dark`};
    // font-family: SofiaProSemiBold;
    // font-size: 51.78px;
    // color: #33343E;
    letter-spacing: -1.62px;
    // line-height: 49.27px;
`

const HangingHeading = styled.h4`
    ${tw`font-serif text-lg font-light text-grey my-4`}
    letter-spacing: -0.01em;
`


export default function about(props) {

    const [headerActive, setHeaderActive] = useState(false);
    const [isMenuActive, setMenuActive] = useState(true);


    return (
        <Layout>

            <SEO title="About Us | Clearcut design"
                keywords={[`Clearcut`, `Design`, `Product Design`, 'Product Development', 'Product Marketing', 'Web Design', 'UX Design', 'UI Design', 'React Developers', 'Digital Design Agency', 'Full service creative agency', 'Clearcut Agency']} />

            <SectionOne>
                <Row css={`${tw`px-12`}`}>
                    <div css={tw`w-full md:w-2/3 mt-44`}>
                        <Gs.HeroHeading css={tw`w-full text-grey-lightest mb-4`}>We are Clearcut</Gs.HeroHeading>
                        <h2 css={`${tw`text-gr font-light text-grey-light opacity-70`}`}>A Design Centric Product Innovation Team.</h2>
                    </div>
                </Row>
            </SectionOne>

            <SectionTwo>
                <Row justifyBetween
                    css={tw`my-16 md:flex-row flex-col-reverse px-12`}>
                    {/* <div css={tw`w-full -mx-4`}> */}

                    <div css={tw`w-full md:w-2/5 md:px-4`}>
                        <HangingHeading>Why do we do all this?</HangingHeading>
                        <SectionTitle css={`${tw`w-4/5`}`}>Bad products make success hard</SectionTitle>
                        <p>
                            Poor products either inhibit your speed towards success, or in the best case scenario, they cloud your business's true potential.
                        </p>
                    </div>

                    <div css={tw`w-full md:w-2/5 md:px-4`}>
                        <img css={tw`w-full`} src={require('../images/why-we-do-illustration.svg')} />
                    </div>

                    {/* </div> */}
                </Row>
                <Row justifyBetween
                    css={tw`my-16 px-12`}>
                    {/* <div css={tw`w-full -mx-4`}> */}

                    <div css={tw`w-full md:w-2/5 md:px-4`}>
                        <img css={tw`w-full`} src={require('../images/how-we-do-illustration.svg')} />
                    </div>

                    <div css={tw`w-full md:w-2/5 md:px-4`}>
                        <HangingHeading>How do we do all this?</HangingHeading>
                        <SectionTitle css={`${tw`w-4/5`}`}>We begin everything with empathy.</SectionTitle>
                        <p>Ability to empathize is the reason we can be good at design, development, marketing or entrepreneurship. If we look past the skillset, the knowledge of tools, business, technology or the market, we discovered that emotional intelligence is the factor that gives us a true competitive advantage.</p>
                    </div>

                    {/* </div> */}
                </Row>

                <Row justifyBetween
                    css={tw`my-16 md:flex-row flex-col-reverse px-12`}>
                    {/* <div css={tw`w-full -mx-4`}> */}

                    <div css={tw`w-full md:w-2/5 md:px-4`}>
                        <HangingHeading>What do we do?</HangingHeading>
                        <SectionTitle css={`${tw`w-4/5`}`}>From Idea to Design to Dev to Marketing.</SectionTitle>
                        <p>We can play a role at any point of your journey — from the point where your idea is still an idea, to the point where you've already disrupted an industry and need to improve. </p>
                    </div>

                    <div css={tw`w-full md:w-2/5 md:px-4`}>
                        <img css={tw`w-full`} src={require('../images/what-we-do-illustration.svg')} />
                    </div>

                    {/* </div> */}
                </Row>


                <div css={`${tw`flex flex-wrap items-start px-12 py-12 lg:py-24 bg-grey-lightest w-full md:max-w-row mx-auto md:justify-end mt-28`}`}>
                    <div css={`${tw`hidden md:flex flex-1 h-px bg-grey-light mt-4 opacity-20 mr-12`}`}>
                    </div>
                    <div css={`${tw`lg:w-gr2/3 px-2`}`}>
                        <h4 css={`${tw`text-150gr tracking-tight lg:w-2/3 font-semibold text-grey-dark`}`}>
                            Rare equilibrium in the world of design
                        </h4>
                        <p css={`${tw`mt-12`}`}>
                            We are business thinkers, design evangelists, technologists, and experts in cognitive psychology. Whether it is your website, your brand, your next billion dollar product, we'll build them with a deep insight into converting these brands and software into successful businesses.
                        </p>
                    </div>
                </div>


            </SectionTwo>

            <Footer />

        </Layout>
    );
}